import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {EditionView} from "./EditionView";

export const EditionViewPage = () => {
    const match = useAuthenticatedRouteMatch("/edition/");

    if (match) {
        return (
            <TopLevelTransition>
                <EditionView />
            </TopLevelTransition>
        )
    }
}