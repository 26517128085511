import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {VersionView} from "./VersionView";

export const VersionViewPage = () => {
    const match = useAuthenticatedRouteMatch("/version/");

    if (match) {
        return (
            <TopLevelTransition>
                <VersionView />
            </TopLevelTransition>
        )
    }
}
