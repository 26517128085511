import React from 'react';

import {AppHeader} from "../components/EkulturHeader/AppHeader";
import {CssBaseline} from "@mui/material";
import {WhenHeaderIsReady} from "../components/EkulturHeader/WhenHeaderIsReady";
import {BrowserRouter as Router} from "react-router-dom";
import {Theme} from "./Theme";
import preval from 'preval.macro'
import {AppProvider} from "./appContext";
import {TranslationMenu} from "../components/TranslationNavigation/TranslationMenu";
import {AppContent} from "./AppContent";
import {AppRoutesAnonymous} from "./AppRoutesAnonymous";
import {WithUserdata} from "../auths/WithUserdata";
import {AppRoutesAuthenticated} from "./AppRoutesAuthenticated";


export const App = () => {
    const buildDate = preval`module.exports = new Date().toDateString();`
    console.log(buildDate)

    if(!window._env_ || !window._env_.REACT_APP_API_GATEWAY) {
        return null;
    }

    return (
        <Theme>
            <React.StrictMode>
                <Router>
                    <AppProvider>
                        <CssBaseline />
                        <AppHeader />
                        <WhenHeaderIsReady>
                            <AppRoutesAnonymous />
                            <WithUserdata>
                                <TranslationMenu />
                                <AppContent>
                                    <AppRoutesAuthenticated />
                                </AppContent>
                            </WithUserdata>
                        </WhenHeaderIsReady>
                    </AppProvider>
                </Router>
            </React.StrictMode>
        </Theme>
    )
}