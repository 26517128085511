import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "./ProgressIndicator";
import {WithAuthentication} from "../auths/WithAuthentication";
import {AuthsProvider} from "../auths/authsContext";
import {HeaderProvider} from '@ekultur/header-microfrontend';

export const MUSEUMS_RECEIVED = 'museumsReceived';
export const APPS_RECEIVED = 'appsReceived';
export const RENDERED = 'rendered';
export const MENU_OPENED = 'menuOpened';
export const MENU_CLOSED = 'menuClosed';

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

const initialState = {
    museums: [],
    apps: [],
    menuOpen: false,
    rendered: false
}

const appReducer = (state, action) => {
    console.debug(action);
    switch (action.type) {
        case MUSEUMS_RECEIVED:
            return {
                ...state,
                museums: action.museums
            }
        case APPS_RECEIVED:
            return {
                ...state,
                apps: action.apps
            }
        case RENDERED:
            return {
                ...state,
                rendered: true
            }
        case MENU_OPENED:
            return {
                ...state,
                menuOpen: true
            }
        case MENU_CLOSED:
            return {
                ...state,
                menuOpen: false
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(appReducer, {...initialState}, undefined);
    const {t, ready} = useTranslation('virtueltmuseumAdmin', {useSuspense: false});

    if (!ready) {
        return <ProgressIndicator/>
    }

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                <AppTranslationContext.Provider value={t}>
                    <HeaderProvider>
                        <AuthsProvider>
                            <WithAuthentication>
                                {children}
                            </WithAuthentication>
                        </AuthsProvider>
                    </HeaderProvider>
                </AppTranslationContext.Provider>
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (undefined === context) {
        throw new Error(`useAppState must be used witin an AppProvider`);
    } else {
        return context;
    }
}

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if (undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
}

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext);
    if (undefined === context) {
        throw new Error("useAppTranslation must be used within an AppProvider")
    } else {
        return context;
    }
}