import {useAuthsState} from "./authsContext";
import {useHasAppAccess} from "./useHasAppAccess";
import {useMatch} from "react-router";

export const useAuthenticatedRouteMatch = path => {
    const {userIsAuthenticated} = useAuthsState();
    const hasAccess = useHasAppAccess();
    const match = useMatch(path);

    if(userIsAuthenticated && hasAccess) {
        return match;
    } else {
        return false;
    }
}