import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import {Hidden, Toolbar} from "@mui/material";
import {useAuthsState} from "../../auths/authsContext";
import {MENU_CLOSED, useAppDispatch, useAppState} from "../../app/appContext";
import {MenuTranslation} from "./components/MenuTranslation";
import {useNavigate} from "react-router";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
            zIndex: theme.zIndex.appBar - 1,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    drawerTitle: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export const TranslationMenu = () => {
    const classes = useStyles();
    const {menuOpen} = useAppState()
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {userIsAuthenticated} = useAuthsState();

    const onMenuClick = url => {
        dispatch({type: MENU_CLOSED})
        navigate(url)
    }

    if(userIsAuthenticated) {
        return (
            <nav className={classes.drawer}>
                <Hidden mdUp implementation={"css"}>
                    <Drawer
                        open={menuOpen}
                        onClose={() => dispatch({type: MENU_CLOSED})}
                        PaperProps={{
                            elevation: 4
                        }}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerContainer}>
                            <MenuTranslation onMenuClick={onMenuClick} />
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <div className={classes.drawerContainer}>
                            <Toolbar />
                            <MenuTranslation onMenuClick={onMenuClick} />
                        </div>
                    </Drawer>
                </Hidden>
            </nav>
        );
    } else {
        return null;
    }
}