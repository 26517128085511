import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {TooltipView} from "./TooltipView";

export const TooltipViewPage = () => {
    const match = useAuthenticatedRouteMatch("/tooltip/");

    if (match) {
        return (
            <TopLevelTransition>
                <TooltipView />
            </TopLevelTransition>
        )
    }
}