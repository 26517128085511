import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {UpcomingView} from "./UpcomingView";

export const UpcomingViewPage = () => {
    const match = useAuthenticatedRouteMatch("/upcoming/");

    if (match) {
        return (
            <TopLevelTransition>
                <UpcomingView />
            </TopLevelTransition>
        )
    }
}