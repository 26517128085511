import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

export const FaqDetailPane = ({row}) => {

    return (
        <Grid>
            Test
        </Grid>
    )
}