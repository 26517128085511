import React, {useEffect, useMemo, useState} from "react";

import {ApiService} from "../../services/ApiService";
import {TranslationList} from "../../components/TranslationList/TranslationList";

export const PriorityView = () => {
    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);
    const [data, setData] = useState([]);
    const columns = useMemo(() => {
        return [
            {
                accessorKey: "value",
                header: "Value"
            },
            {
                accessorKey: "name",
                header: "Name"
            }
        ]
    }, [])

    useEffect(() => {
        api.getPriorities().then((d) => {
            setData(d);
        })
    }, [])

    return (
        <TranslationList
            data={data}
            columns={columns}
            enableRowActions={false}
            enableDetailPane={false}
        />
    )
}