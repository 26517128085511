import "whatwg-fetch";
import {ApiService} from "./ApiService";

export class HttpService {
    combineConfigs(...configs) {
        return configs.reduce((res, cfg) => {
            return this.combineDeep(res, cfg);
        });
    }

    combineDeep(obj1, obj2) {
        if (typeof obj1 !== "object" || typeof obj2 !== "object") {
            return obj2 || obj1;
        }
        return Object.keys(obj2).reduce((obj, key) => {
            let value = obj2[key];
            if (value !== undefined) {
                if (typeof value === "object") {
                    if (value instanceof Headers) {
                        if (obj1[key]) {
                            value = obj1[key];
                            new Headers(obj2[key]).forEach((header, name) => {
                                if (value.has(name)) {
                                    value.delete(name);
                                }
                                if (name && header) {
                                    value.append(name, header);
                                }
                            });
                        }
                    } else {
                        value = this.combineDeep(obj[key], value);
                    }
                }

                // eslint-disable-next-line no-param-reassign
                obj[key] = value;
            }
            return obj;
        }, obj1);
    }

    getBaseUrl() {
        throw new Error("getBaseUrl() needs to be implemented in extending class!");
    }

    getDefaultConfig() {
        return this.combineConfigs({
            headers: new Headers({
                Accept: "application/json",
                "Content-Type": "application/json",
            }),
        });
    }

    getPathPrefix() {
        return "/api";
    }

    async delete(path, config) {
        return this.http(path, "DELETE", config);
    }

    async get(path, config) {
        return this.http(path, "GET", config);
    }

    async post(path, body, config = null, stringifyBody = true) {
        return this.http(path, "POST", {
            ...config,
            body: stringifyBody ? JSON.stringify(body) : body,
        });
    }

    async put(path, body, config = null, stringifyBody = true) {
        return this.http(path, "PUT", {
            ...config,
            body: stringifyBody ? JSON.stringify(body) : body,
        });
    }

    async http(path, method, config = null) {
        try {
            if (!this.getBaseUrl()) {
                console.warn("HttpService - BaseURL not set");
            }

            const cfg = this.combineConfigs(this.getDefaultConfig(), config || {}, {
                method,
                timeout: 100000
            });

            const apiRootUrl = `${this.getBaseUrl()}${this.getPathPrefix()}`;

            let res = await fetch(`${apiRootUrl}${path}`, cfg);

            if (res.ok) {
                return res;
            }

            throw res;
        } catch (e) {
            console.error("HttpService - Request failed: ", e);
            throw e;
        }
    }
}
