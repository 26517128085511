import React from 'react';
import Fade from "@mui/material/Fade";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    childrenContainer: {
        height: "100%",
        width: "100%"
    }
}))

export const TopLevelTransition = ({children}) => {
    const classes = useStyles();

    return (
        <Fade
            in={true}
            unmountOnExit={true}
        >
            <div className={classes.childrenContainer}>
                {children}
            </div>
        </Fade>
    )
}