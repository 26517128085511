import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {FaqCategoryView} from "./FaqCategoryView";

export const FaqCategoryViewPage = () => {
    const match = useAuthenticatedRouteMatch("/faq-categories/");

    if (match) {
        return (
            <TopLevelTransition>
                <FaqCategoryView />
            </TopLevelTransition>
        )
    }
}