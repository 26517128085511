import React, {useEffect, useMemo, useState} from "react";
import {ApiService} from "../../services/ApiService";
import {TranslationList} from "../../components/TranslationList/TranslationList";

export const TooltipView = () => {
    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);
    const [data, setData] = useState([]);

    const columns = useMemo(() => {
        return [
            {
                accessorKey: "field_id",
                header: "Field id",
                size: 150
            },
            {
                accessorKey: "field_uuid",
                header: "Field UUID",
                size: 300
            },
            {
                accessorKey:"field",
                header: "Field",
                size: 300
            },
            {
                accessorKey: "objects",
                header: "Objects",
                size: 300
            },
            {
                accessorKey: "no.tooltip",
                header: "Norwegian",
                size: 300
            },
            {
                accessorKey: "sv.tooltip",
                header: "Swedish",
                size: 300
            },
            {
                accessorKey: "en.tooltip",
                header: "English",
                size: 300
            },
            {
                accessorKey: "access_count",
                header: "Count",
                size: 100
            }
        ]
    }, []);

    useEffect(() => {
        api.getTooltips().then((d) => {
            setData(d);
        })
    }, []);

    return (
        <TranslationList
            columns={columns}
            data={data}
            enableDetailPane={false}
        />
    )
}