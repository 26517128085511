import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {PriorityView} from "./PriorityView";

export const PriorityViewPage = () => {
    const match = useAuthenticatedRouteMatch("/priority/");

    if (match) {
        return (
            <TopLevelTransition>
                <PriorityView />
            </TopLevelTransition>
        )
    }
}