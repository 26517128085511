import React, {useEffect, useMemo, useState} from "react";

import {ApiService} from "../../services/ApiService";
import {TranslationList} from "../../components/TranslationList/TranslationList";

export const WhatsNewView = () => {
    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);
    const [data, setData] = useState([]);
    const columns = useMemo(() => {
        return [
            {
                accessorKey: "version",
                header: "Version"
            },
            {
                accessorKey: "no.title",
                header: "Norwegian Title"
            },
            {
                accessorKey: "no.description",
                header: "Norwegian Description"
            },
            {
                accessorKey: "sv.title",
                header: "Swedish Title"
            },
            {
                accessorKey: "sv.description",
                header: "Swedish Description"
            },
            {
                accessorKey: "en.title",
                header: "English Title"
            },
            {
                accessorKey: "en.description",
                header: "English Description"
            },
            {
                accessorKey: "valid_from",
                header: "Valid From"
            }
        ]
    }, []);

    useEffect(() => {
        api.getWhatIsNew().then((d) => {
            setData(d);
        })
    }, [])

    return (
        <TranslationList
            data={data}
            columns={columns}
            enableDetailPane={false}
            enableRowActions={false}
        />
    )
}