import {useAuthsState} from "./authsContext";

export const useIsAppAdmin = () => {
    const {userData} = useAuthsState();

    return userData.appAccess
        && Boolean(
            userData.appAccess.apps.find(app => window._env_.REACT_APP_ID === app.id && app.role.name === 'Admin')
        )

}