import React, {useEffect, useMemo, useState} from "react";
import {ApiService} from "../../services/ApiService";
import {TranslationList} from "../../components/TranslationList/TranslationList";

export const MessageView = () => {
    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);

    const [data, setData] = useState([]);
    const columns = useMemo(() => {
        return [
            {
                accessorKey: "no.title",
                header: "Norwegian Title",
                size: 100
            },
            {
                accessorKey: "no.description",
                header: "Norwegian Description",
                size: 300
            },
            {
                accessorKey: "sv.title",
                header: "Swedish Title",
                size: 300
            },
            {
                accessorKey: "sv.description",
                header: "Swedish Description",
                size: 300
            },
            {
                accessorKey: "en.title",
                header: "English Title",
                size: 300
            },
            {
                accessorKey: "en.description",
                header: "English Description",
                size: 300
            },
            {
                accessorKey: "valid_from",
                header: "Valid From",
                size: 300
            },
            {
                accessorKey: "valid_to",
                header: "Valid To",
                size: 300
            },
            {
                accessorKey: "restricted",
                header: "Restricted",
                size: 50
            },
            {
                accessorKey: "priority",
                header: "Priority",
                size: 300
            }
        ]
    })

    useEffect(() => {
        api.getMessages().then((d) => {
            setData(d);
        })
    }, [])

    return (
        <TranslationList
            columns={columns}
            data={data}
            enableDetailPane={false}
        />
    )
}