import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {WhatsNewView} from "./WhatsNewView";

export const WhatsNewViewPage = () => {
    const match = useAuthenticatedRouteMatch("/whats-new/");

    if (match) {
        return (
            <TopLevelTransition>
                <WhatsNewView />
            </TopLevelTransition>
        )
    }
}