import React from "react";
import {useHeaderState} from "@ekultur/header-microfrontend";
import {ProgressIndicator} from "../../app/ProgressIndicator";
import {useAppState} from "../../app/appContext";

export const WhenHeaderIsReady = ({children}) => {
    const {ready} = useHeaderState();
    const {rendered} = useAppState();

    if(!ready || !rendered) {
        return <ProgressIndicator />
    }

    return (
        <>
            {children}
        </>
    )

}