import React from 'react';
import { createTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import ThemeProvider from "@mui/styles/ThemeProvider";
import {Box} from "@mui/material";
import {blue, grey, pink} from "@mui/material/colors";
import {StyledEngineProvider} from "@mui/material";


export const Theme = ({children}) => {
    const appTheme = createAppTheme();
    const classes = useStyles();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <Box className={classes.root}>
                    {children}
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export const themeOptions = {
    palette: {
        primary: blue,
        secondary: pink,
        background: {
            default: grey[100]
        }
    },
    props: {
        MuiTextField: {
            variant: 'filled',
            color: "primary"
        },
        MuiFormLabel: {
            color: "primary"
        },
        MuiSelect: {
            variant: 'filled',
            color: "primary"
        },
    }
};

const useStyles = makeStyles(() => ({
    root: {
        zIndex: 1,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    },
}));

const createAppTheme = () => createTheme(themeOptions);