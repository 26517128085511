import React, {useEffect, useMemo, useState} from "react";
import {ApiService} from "../../services/ApiService";
import {TranslationList} from "../../components/TranslationList/TranslationList";

export const FaqView = () => {
    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);

    const [data, setData] = useState([]);

    const columns = useMemo(() => {
        return [
            {
                accessorKey: "category",
                header: "Category",
                size: 150
            },
            {
                accessorKey: "no.question",
                header: "Norwegian Question",
                size: 300
            },
            {
                accessorKey: "no.answer",
                header: "Norwegian Answer",
                size: 300
            },
            {
                accessorKey: "sv.question",
                header: "Swedish Question",
                size: 300
            },
            {
                accessorKey: "sv.answer",
                header: "Swedish Answer",
                size: 300
            },
            {
                accessorKey: "en.question",
                header: "English Question",
                size: 300
            },
            {
                accessorKey: "en.answer",
                header: "English Answer",
                size: 300
            },
            {
                accessorKey: "url",
                header: "Url",
                size: 100
            }
        ]
    }, []);

    useEffect(() => {
        api.getFaqs().then((d) => {
            setData(d);
        })
    }, [])

    return (
        <TranslationList
            columns={columns}
            data={data}
            detailPaneType={"Faq"}
        />
    )
}