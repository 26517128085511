import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {ApplicationView} from "./ApplicationView";

export const ApplicationViewPage = () => {
    const match = useAuthenticatedRouteMatch("/application/");

    if (match) {
        return (
            <TopLevelTransition>
                <ApplicationView />
            </TopLevelTransition>
        )
    }
}