import {HttpService} from "./HttpService";

export class ApiService extends HttpService {
    constructor(baseUrl = process.env.REACT_APP_BACKEND_URL) {
        super();
        this.baseUrl = baseUrl;
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    getDefaultConfig() {
        return this.combineConfigs(super.getDefaultConfig(), {
            headers: new Headers({
                Authorization: ApiService.getAuthHeader(),
            }),
            credentials: "include",
        });
    }

    async checkOk(p) {
        const res = await p;
        if (!res?.ok) {
            throw await Error(res);
        }
        return res;
    }

    async json(p) {
        const res = await this.checkOk(p);
        return res.json();
    }

    async getApplications() {
        return this.json(
            this.get("/internal/application")
        )
    }

    async getEditions() {
        return this.json(
            this.get("/internal/edition")
        )
    }

    async getFaqs() {
        return this.json(
            this.get("/internal/faq")
        )
    }

    async getFaqCategories() {
        return this.json(
            this.get("/internal/faq-categories")
        )
    }

    async getMessages() {
        return this.json(
            this.get("/internal/messages")
        )
    }

    async getPriorities() {
        return this.json(
            this.get("/internal/priorities")
        )
    }

    async getServers() {
        return this.json(
            this.get("/internal/servers")
        )
    }

    async getTooltips() {
        return this.json(
            this.get("/internal/tooltips")
        )
    }

    async getTranslations() {
        return this.json(
            this.get("/internal/translations")
        )
    }

    async getUpcomingVersions() {
        return this.json(
            this.get("/internal/upcoming_version")
        )
    }

    async getWhatIsNew() {
        return this.json(
            this.get("/internal/what_is_new")
        )
    }

    async putFaq(faq) {
        return this.json(
            this.post("/internal/faq", faq)
        )
    }

    async putFaqCategories(categories) {
        return this.json(
            this.post("/internal/faq_category", categories)
        )
    }

    async putMessages(messages) {
        return this.json(
            this.post("/internal/messages", messages)
        )
    }

    async putMessageServers(messageServers) {
        return this.json(
            this.post("/internal/message_servers", messageServers)
        )
    }

    async putServers(servers) {
        return this.json(
            this.post("/internal/servers", servers)
        )
    }

    async putTooltips(tooltips) {
        return this.json(
            this.post("/internal/tooltips", tooltips)
        )
    }

    async putTranslations(translations) {
        return this.json(
            this.post("/internal/translations", translations)
        )
    }

    static getAuthHeader() {
        const token = ApiService.getToken();
        return token ? `Bearer ${token}` : "";
    }

    static getToken() {
        return window.localStorage.getItem("kit.access_token");
    }
}