import React from 'react';
import ListSubheader from "@mui/material/ListSubheader";
import Apps from "@mui/icons-material/Apps";
import Button from "@mui/material/Button"
import Campaign from "@mui/icons-material/Campaign";
import Category from "@mui/icons-material/Category";
import Divider from "@mui/material/Divider";
import Dns from "@mui/icons-material/Dns";
import DynamicFeed from "@mui/icons-material/DynamicFeed";
import Hub from "@mui/icons-material/Hub";
import Info from "@mui/icons-material/Info";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import LocalLibrary from "@mui/icons-material/LocalLibrary";
import Message from "@mui/icons-material/Message";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import Quiz from "@mui/icons-material/Quiz";
import Settings from "@mui/icons-material/Settings";
import Translate from "@mui/icons-material/Translate";
import Upcoming from "@mui/icons-material/Upcoming"
import {useLocation} from "react-router-dom";
import {useIsAppAdmin} from "../../../auths/useIsAppAdmin";
import {useHasAppAccess} from "../../../auths/useHasAppAccess";

export const MenuTranslation = ({onMenuClick}) => {
    const location = useLocation();
    const isAppAdmin = useIsAppAdmin();
    const hasAccess = useHasAppAccess();

    if(hasAccess) {
        return (
            <List>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/translation")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Translate />
                        </ListItemIcon>
                        <ListItemText primary={"Translation"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/faq")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Quiz />
                        </ListItemIcon>
                        <ListItemText primary={"FAQ"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/faq-categories")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Category />
                        </ListItemIcon>
                        <ListItemText primary={"FAQ Categories"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/tooltip")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText primary={"Tooltip"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/whats-new")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Campaign />
                        </ListItemIcon>
                        <ListItemText primary={"What's new"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/upcoming")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Upcoming />
                        </ListItemIcon>
                        <ListItemText primary={"Upcoming version"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/message")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Message />
                        </ListItemIcon>
                        <ListItemText primary={"Message"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/edition")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Hub />
                        </ListItemIcon>
                        <ListItemText primary={"Edition"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/application")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Apps />
                        </ListItemIcon>
                        <ListItemText primary={"Application"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/server")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <Dns />
                        </ListItemIcon>
                        <ListItemText primary={"Server"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/priority")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <PriorityHigh />
                        </ListItemIcon>
                        <ListItemText primary={"Priority"} />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        onClick={() => onMenuClick("/manual")}
                        sx={{ width: "100%", padding: 1 }}
                    >
                        <ListItemIcon>
                            <LocalLibrary />
                        </ListItemIcon>
                        <ListItemText primary={"Primus Manual"} />
                    </Button>
                </ListItem>
            </List>
        )
    } else {
        return null;
    }
}