import React, {useEffect, useMemo, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useAuthsState} from "../../auths/authsContext";
import {ApiService} from "../../services/ApiService";
import {TranslationList} from "../../components/TranslationList/TranslationList";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        height: "100%"
    }
}))

export const TranslationView = () => {
    const classes = useStyles();
    const {userData} = useAuthsState();

    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);
    const [data, setData] = useState([])

    const columns = useMemo(() => {
        return [
            {
                accessorKey: "translation_key",
                header: "Key",
                size: 400
            },
            {
                accessorKey: "no.translation",
                header: "Norwegian",
                size: 500
            },
            {
                accessorKey: "sv.translation",
                header: "Swedish",
                size: 500
            },
            {
                accessorKey: "en.translation",
                header: "English",
                size: 500
            }
        ]
    }, [])

    useEffect(() => {
        api.getTranslations().then((d) => {
            setData(d);
        })
    }, [])

    const handleSaveRow = async ({exitEditingMode, row, values}) => {
        data[row.index].translation_key = values["translation_key"];
        data[row.index].no.translation = values["no.translation"];
        data[row.index].sv.translation = values["sv.translation"];
        data[row.index].en.translation = values["en.translation"];
        setData([...data]);
        exitEditingMode();
        console.log(userData);
    };

    return (
        <TranslationList
            columns={columns}
            data={data}
            detailPaneType={"Translation"}
            handleSaveRow={handleSaveRow}
        />
    )
}