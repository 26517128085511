import {useAuthsState} from "./authsContext";

export const useHasAppAccess = () => {
    const {userData} = useAuthsState();

    let access = false;

    for (const museum in userData.appAccess.museums) {
        if (userData.appAccess.museums[museum].applications.find(app => window._env_.REACT_APP_ID === app.id &&
            (app.role.name === 'Admin' || app.role.name === 'User'))) {
            access = true;
        }
    }

    return userData.appAccess && access;
}