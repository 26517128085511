import React from 'react';
import {ApplicationViewPage} from "../views/ApplicationView/ApplicationViewPage";
import {EditionViewPage} from "../views/EditionView/EditionViewPage";
import {FaqViewPage} from "../views/FaqView/FaqViewPage";
import {FaqCategoryViewPage} from "../views/FaqCategoryView/FaqCategoryViewPage";
import {MessageViewPage} from "../views/MessageView/MessageViewPage";
import {PriorityViewPage} from "../views/PriorityView/PriorityViewPage";
import {ServerViewPage} from "../views/ServerView/ServerViewPage";
import {TooltipViewPage} from "../views/TooltipView/TooltipViewPage";
import {TranslationViewPage} from "../views/TranslationView/TranslationViewPage";
import {UpcomingViewPage} from "../views/UpcomingView/UpcomingViewPage";
import {VersionViewPage} from "../views/VersionView/VersionViewPage";
import {WhatsNewViewPage} from "../views/WhatsNewView/WhatsNewViewPage";

export const AppRoutesAuthenticated = () => {
    return (
        <>
            <ApplicationViewPage />
            <EditionViewPage />
            <FaqViewPage />
            <FaqCategoryViewPage />
            <MessageViewPage />
            <PriorityViewPage />
            <ServerViewPage />
            <TooltipViewPage />
            <TranslationViewPage />
            <UpcomingViewPage />
            <VersionViewPage />
            <WhatsNewViewPage />
        </>
    )
}