import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {FaqView} from "./FaqView";

export const FaqViewPage = () => {
    const match = useAuthenticatedRouteMatch("/faq/");

    if (match) {
        return (
            <TopLevelTransition>
                <FaqView />
            </TopLevelTransition>
        )
    }
}