import React from "react";
import {useAuthenticatedRouteMatch} from "../../auths/useAuthenticatedRouteMatch";
import {TopLevelTransition} from "../../components/TopLevelTransition";
import {MessageView} from "./MessageView";

export const MessageViewPage = () => {
    const match = useAuthenticatedRouteMatch("/message/");

    if (match) {
        return (
            <TopLevelTransition>
                <MessageView />
            </TopLevelTransition>
        )
    }
}