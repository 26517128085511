import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {codeToToken} from "@ekultur/authentication";
import {useAuthsDispatch, USER_LOGGED_IN} from "./authsContext";
import {useMatch, useNavigate} from "react-router";

export const Callback = () => {
    const match = useMatch("/oauth2/callback");
    const location = useLocation();
    const dispatch = useAuthsDispatch();
    const navigate = useNavigate()
    const dispatchCallback = useCallback(dispatch, [dispatch]);

    useEffect(() => {
        if(match) {
            const searchParams = new URLSearchParams(location.search);
            codeToToken({
                code: searchParams.get('code'),
                state: searchParams.get('state'),
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: data => {
                    dispatchCallback({type: USER_LOGGED_IN})
                    navigate(data.previousPath)
                },
                sameSite: process.env.REACT_APP_ENVIRONMENT === 'beta'
            })
        }
    }, [location, navigate, dispatchCallback, match])

    return null
}