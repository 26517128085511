import React from 'react';
import {WithHeader, Header} from '@ekultur/header-microfrontend';
import {useAuthsDispatch, useAuthsState, USER_LOGGED_OUT} from "../../auths/authsContext";
import {login, logout} from "@ekultur/authentication";
import {useLocation} from "react-router-dom";
import {MENU_OPENED, RENDERED, useAppDispatch} from "../../app/appContext";

export const AppHeader = () => {
    const {userIsAuthenticated} = useAuthsState();
    const dispatchAuths = useAuthsDispatch();
    const appDispatch = useAppDispatch();
    const location = useLocation();

    const loginHandler = () => {
        const prevPath = location.state && location.state.previousPath ? location.state.previousPath : "/"
        login({
            locationPostLogin: prevPath,
            loginHost: window._env_.REACT_APP_LOGIN_HOST
        })
    }

    const logoutHandler = () => {
        logout({
            onLogout: () => dispatchAuths({type: USER_LOGGED_OUT})
        })
    }
    return (
        <WithHeader>
            <Header
                appId={window._env_.REACT_APP_ID}
                loggedIn={userIsAuthenticated}
                mobileOpenHandler={() => appDispatch({type: MENU_OPENED})}
                logoutHandler={() => logoutHandler()}
                loginHandler={loginHandler}
                mobileOpen={false}
                onRender={() => appDispatch({type: RENDERED})}
                version={window._env_.REACT_APP_VERSION}
            />
        </WithHeader>
    )
}