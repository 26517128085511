import React, {useMemo, useState} from 'react';
import {Box, Button, TextField, Tooltip, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MaterialReactTable from "material-react-table";

export const TranslationDetailPane = ({row}) => {
    const [data, setData] = useState([]);
    const columns = useMemo(() => {
        return [
            {
                accessorKey: "application",
                header: "Application",
                size: 400
            },
            {
                Cell: ({cell, row}) => {
                    return(
                        <Box>
                            <Tooltip
                                arrow
                                title={`Created by ${row.original["no.created_by"]} on ${row.original["no.created_date"]}`}>
                                <Typography placement="bottom-start">
                                    {cell.getValue()}
                                </Typography>
                            </Tooltip>
                        </Box>
                    )

                },
                accessorFn: (row) => `${row["no.translation"]}`,
                header: "Norwegian",
                size: 500
            },
            {
                accessorFn: (row) => `${row["sv.translation"]}`,
                header: "Swedish",
                size: 500
            },
            {
                accessorFn: (row) => `${row["en.translation"]}`,
                header: "English",
                size: 500
            }
        ]
    }, [])

    if (row.original.hasOwnProperty("translation_special") && data.length === 0) {
        const temp = [];

        if (Object.keys(row.original.translation_special).length > 0) {
            for (const item in row.original.translation_special) {
                temp.push({
                    "application": item,
                    "no.translation": row.original.translation_special[item].no.translation,
                    "no.created_by": row.original.translation_special[item].no.created_by,
                    "no.created_date": row.original.translation_special[item].no.created_date,
                    "no.history.translation": row.original.translation_special[item].no.history.translation,
                    "no.history.created_by": row.original.translation_special[item].no.history.created_by,
                    "no.history.created_date": row.original.translation_special[item].no.history.created_date,
                    "sv.translation": row.original.translation_special[item].sv.translation,
                    "sv.created_by": row.original.translation_special[item].sv.created_by,
                    "sv.created_date": row.original.translation_special[item].sv.created_date,
                    "sv.history.translation": row.original.translation_special[item].sv.history.translation,
                    "sv.history.created_by": row.original.translation_special[item].sv.history.created_by,
                    "sv.history.created_date": row.original.translation_special[item].sv.history.created_date,
                    "en.translation": row.original.translation_special[item].en.translation,
                    "en.created_by": row.original.translation_special[item].en.created_by,
                    "en.created_date": row.original.translation_special[item].en.created_date,
                    "en.history.translation": row.original.translation_special[item].en.history.translation,
                    "en.history.created_by": row.original.translation_special[item].en.history.created_by,
                    "en.history.created_date": row.original.translation_special[item].en.history.created_date
                })
            }

            setData(temp);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <TextField label={"Translation Key"} value={row.original.translation_key} fullWidth></TextField>
            </Grid>
            <Grid container paddingX={2} xs={12}>
                <Grid container xs={4} >
                    <TextField label={"Norwegian"} value={row.original.no.translation} sx={{"margin": "10px 10px 10px 0"}}></TextField>
                    <Tooltip disableHoverListener={row.original.no.history.translation === ""}
                             placement={"right"}
                             title={"Created " + row.original.no.history.created_date + " by "
                                 + row.original.no.history.created_by}
                             arrow>
                        <Grid  margin={"auto 0"}>
                            <Typography variant={"subtitle2"}>History</Typography>
                            {row.original.no.history.translation}
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid container xs={4}>
                    <TextField label={"Swedish"} value={row.original.sv.translation} sx={{"margin": "10px 10px 10px 0"}}></TextField>
                    <Tooltip disableHoverListener={row.original.sv.history.translation === ""}
                             placement={"right"}
                             title={"Created " + row.original.sv.history.created_date + " by "
                                 + row.original.sv.history.created_by}
                             arrow>
                        <Grid  margin={"auto 0"}>
                            <Typography variant={"subtitle2"}>History</Typography>
                            {row.original.sv.history.translation}
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid container xs={4}>
                    <TextField label={"English"} value={row.original.en.translation} sx={{"margin": "10px 10px 10px 0"}}></TextField>
                    <Tooltip disableHoverListener={row.original.en.history.translation === ""}
                             placement={"right"}
                             title={"Created " + row.original.en.history.created_date + " by "
                                 + row.original.en.history.created_by}
                             arrow>
                        <Grid  margin={"auto 0"}>
                            <Typography variant={"subtitle2"}>History</Typography>
                            {row.original.en.history.translation}
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
            {data.length > 0 &&
                <Grid xs={12}>
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableSorting={false}
                        enableTopToolbar={false}
                        muiTableBodyRowProps={{ hover: false }}
                    />
                </Grid>
            }
            <Grid container spacing={2}>
                <Grid>
                    <Button variant={"contained"} color={"info"}>
                        Save
                    </Button>
                </Grid>
                <Grid>
                    <Button variant={"outlined"}>
                        Add application specific translation
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}