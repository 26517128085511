import React, {useEffect, useRef, useState} from 'react';
import {Container, Toolbar} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden"
    },
    container: {
        height: props => `calc(100% - ${props.toolbarHeight}px)`,
        // height: "80%",
        overflowY: "auto",
    }
}))

export const AppContent = ({children}) => {
    const toolbarRef = useRef();
    const [toolbarHeight, setToolbarHeight] = useState(64);
    const classes = useStyles({
        toolbarHeight: toolbarHeight
    });

    useEffect(() => {
        if(toolbarRef.current) {
            setToolbarHeight(toolbarRef.current.clientHeight)
        }
    }, [setToolbarHeight])

    return (
        <div className={classes.root}>
            <Toolbar ref={toolbarRef}/>
            <Container maxWidth="100%" className={classes.container}>
                {children}
            </Container>
        </div>
    )
}