import React, {useEffect, useMemo, useState} from "react";

import {ApiService} from "../../services/ApiService";
import {TranslationList} from "../../components/TranslationList/TranslationList";

export const FaqCategoryView = () => {
    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);

    const [data, setData] = useState([]);
    const columns = useMemo(() => {
        return [
            {
                accessorKey: "category_key",
                header: "Key",
                size: 200
            },
            {
                accessorKey: "no.category",
                header: "Norwegian",
                size: 400
            },
            {
                accessorKey: "sv.category",
                header: "Swedish",
                size: 400
            },
            {
                accessorKey: "en.category",
                header: "English",
                size: 400
            }
        ]
    }, [])

    useEffect(() => {
        api.getFaqCategories().then((d) => {
            setData(d);
        })
    }, [])

    return (
        <TranslationList
            columns={columns}
            data={data}
            enableDetailPane={false}
        />
    )
}