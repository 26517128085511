import React, {useEffect, useMemo, useState} from "react";
import MaterialReactTable from "material-react-table";
import {Box, Button, IconButton, MenuItem} from "@mui/material";
import {DeleteForever, Edit} from "@mui/icons-material";

import {ApiService} from "../../services/ApiService";
import {FaqDetailPane} from "./components/FaqDetailPane";
import {TranslationDetailPane} from "./components/TranslationDetailPane";

// Temporary imports
import no from "../../temp/translations/no.json";
import sv from "../../temp/translations/sv.json";
import en from "../../temp/translations/en.json";
import faq_entries from "../../temp/faq/faq.json";
import faq_category from "../../temp/faq/faq_category.json";
import message from "../../temp/message/message.json";
import message_server from "../../temp/message/message_server.json";
import server from "../../temp/server/server.json";
import tooltip from "../../temp/tooltip/tooltip.json";

export const TranslationList = ({
    columns,
    data,
    detailPaneType,
    enableDetailPane=true,
    enableEditing = true,
    enableRowActions=true,
    handleSaveRow = null
}) => {
    const api = new ApiService(process.env.REACT_APP_BACKEND_URL);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50
    })

    const importLocal = () => {
        const faqs = {faq: []};
        const faq_categories = {categories: []};
        const messages = {messages: []};
        const message_servers = {message_servers: []};
        const servers = {servers: []};
        const tooltips = {tooltips: []};
        const translations = {translations: []};

        for (const translation in no) {
            const entry = {
                translation_key: translation,
                created_by: "Importer",
                locale: [
                    {
                        translation: no[translation],
                        locale: "no",
                        created_by: "Importer",
                    }
                ]
            }
            if (sv[translation] !== translation) {
                entry.locale.push({
                    translation: sv[translation],
                    locale: "sv",
                    created_by: "Importer",
                })
            }
            if (en[translation] !== translation) {
                entry.locale.push({
                    translation: en[translation],
                    locale: "en",
                    created_by: "Importer"
                })
            }

            translations.translations.push(entry)
        }

        api.putTranslations(translations).then((res) => {
            console.log(res);

            for (const cat in faq_category) {
                const entry = {
                    category_key: faq_category[cat].key,
                    created_by: "Importer",
                    no: faq_category[cat].no,
                    sv: faq_category[cat].sv,
                    en: faq_category[cat].en
                }

                faq_categories.categories.push(entry);
            }

            api.putFaqCategories(faq_categories).then((res) => {
                console.log(res);

                for (const f in faq_entries) {
                    let key = "";

                    for (const cat in faq_category) {
                        if (faq_category[cat].id == faq_entries[f].category_id) {
                            key = faq_category[cat].key;
                        }
                    }

                    const entry = {
                        category_key: key,
                        created_by: "Importer",
                        updated_by: "Importer",
                        url: faq_entries[f].url,
                        no_question: faq_entries[f].no_question,
                        no_answer: faq_entries[f].no_answer,
                        sv_question: faq_entries[f].sv_question,
                        sv_answer: faq_entries[f].sv_answer,
                        en_question: faq_entries[f].en_question,
                        en_answer: faq_entries[f].en_answer
                    }

                    faqs.faq.push(entry);
                }

                api.putFaq(faqs).then((res) => {
                    console.log(res);

                    for (const msg in message) {
                        const entry = {
                            no_title: message[msg].no_title,
                            no_description: message[msg].no_description,
                            sv_title: message[msg].sv_title,
                            sv_description: message[msg].sv_description,
                            en_title: message[msg].en_title,
                            en_description: message[msg].en_description,
                            from_date: message[msg].from_date,
                            to_date: message[msg].to_date,
                            restricted: message[msg].restricted,
                            priority_id: message[msg].priority_id,
                            created_date: message[msg].created_date,
                            created_by: "Importer",
                            updated_by: "Importer",
                            updated_date: message[msg].updated_date
                        }
                        messages.messages.push(entry);
                    }

                    api.putMessages(messages).then((res) => {
                        console.log(res);

                            for (const serv in server) {
                                const entry = {
                                    server_name: server[serv].name,
                                    server_key: server[serv].server_key,
                                    created_by: "Importer"
                                }

                                servers.servers.push(entry);
                            }

                            api.putServers(servers).then((res) => {
                                console.log(res);
                                for (const serv in message_server) {
                                    let title = ""
                                    let server_key = "";

                                    for (const msg in message) {
                                        if (message[msg].id == message_server[serv].message_id) {
                                            if (message[msg].no_title != "") {
                                                title = message[msg].no_title;
                                            }
                                            else if (message[msg].sv_title != "") {
                                                title = message[msg].sv_title
                                            }
                                            else if (message[msg].en_title != "") {
                                                title = message[msg].en_title
                                            }
                                        }
                                    }
                                    for (const srv in server) {
                                        if (server[srv].id == message_server[serv].server_id) {
                                            server_key = server[srv].server_key;
                                        }
                                    }

                                    const entry = {
                                        message_title: title,
                                        server_key: server_key
                                    }

                                    message_servers.message_servers.push(entry);
                                }

                                api.putMessageServers(message_servers).then((res) => {
                                    console.log(res);

                                    let i = 99000

                                    for (const t in tooltip) {
                                        const entry = {
                                            field_id: tooltip[t].field_id,
                                            field_name: tooltip[t].field_name,
                                            field_uuid: tooltip[t].field_uuid,
                                            objects: tooltip[t].objects,
                                            no: tooltip[t].no,
                                            no_spectrum: tooltip[t].no_spectrum,
                                            sv: tooltip[t].sv,
                                            sv_spectrum: tooltip[t].sv_spectrum,
                                            en: tooltip[t].en,
                                            en_spectrum: tooltip[t].en_spectrum,
                                            access_count: tooltip[t].access_count,
                                            created_by: "Importer",
                                            created_date: tooltip[t].created_date,
                                            updated_by: "Importer",
                                            updated_date: tooltip[t].updated_date
                                        }

                                        if (entry.access_count == "") {
                                            entry.access_count = 0;
                                        }

                                        if (entry.field_uuid == "") {
                                            entry.field_uuid = i.toString()
                                            i++;
                                        }

                                        tooltips.tooltips.push(entry);
                                    }

                                    api.putTooltips(tooltips).then((res) => {
                                        console.log(res);
                                        window.location.reload();
                                    })
                                })
                            })
                    })
                })
            })
        });
    }

    if (enableDetailPane) {
        return(
            <MaterialReactTable
                columns={columns}
                data={data}
                editingMode={"row"}
                enableColumnFilterModes
                enableEditing={enableEditing}
                enableStickyHeader
                enableRowActions={enableRowActions}
                initialState={{showColumnFilters: true}}
                muiTableContainerProps={{sx: {maxHeight: "calc(100vh - 170px)"}}}
                onEditingRowSave={handleSaveRow}
                state={{pagination}}

                renderDetailPanel={({row}) => {
                    let pane = null;

                    if (detailPaneType == "Translation") pane = <TranslationDetailPane row={row}/>
                    if (detailPaneType == "Faq") pane = <FaqDetailPane row={row} />

                    return pane;
                }}

                renderTopToolbarCustomActions={({table}) => {
                    return(
                        <Button
                            onClick={importLocal}
                        >
                            Import Translations (Remove me)
                        </Button>
                    )
                }}
            />
        )
    }
    else {
        return(
            <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes
                enableStickyHeader
                enableRowActions={enableRowActions}
                initialState={{showColumnFilters: true}}
                muiTableContainerProps={{sx: {maxHeight: "calc(100vh - 170px)"}}}
                state={{pagination}}
            />
        )
    }


}